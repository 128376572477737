<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow class="mt-3">
      <CCol sm="12">
        <CRow v-if="this.currentStep === 1">
          <CCol sm="6">
            <CSelect
              :horizontal="{label:'col-sm-12 col-lg-4 col-lg-2',input:'col-sm-12 col-lg-3 col-lg-3'}"
              :label="$t('label.statusBlock')"
              addLabelClasses="text-right"
              @change="filterCondition($event.target.value)"
              :options="conditionStatusFormatted"
              :disabled="!fgOperative"
              :v-model="statusConditionSelected"
              v-if="this.currentStep === -1"
            />
          </CCol>
          <CCol sm="6"  class="d-flex align-items-center justify-content-end">
              <CButton
                color="transparent"
                class=" col-sm-auto"
                size="sm"
                @click="leyendModal = true"
              >
              <div>
                <img width="40px" height="40px" src="/img/iconos/GIF_AYUDA.gif">
              </div>
              </CButton>
            </CCol>
        </CRow>
        <CRow class="mb-5" v-if="this.currentStep === 1">
          <CCol sm="12">
            <div class="status-wrapper">
              <label>{{ $t('label.assignconditions')}}</label>
              <div class="position-relative">
                <div class="status-container">
                  <div
                    v-for="item in status"
                    :key="item.YardSlotStatusId"
                    class="status-item"
                    :class="isStatusSelected(item)"
                    @click.prevent="selecStatus(item)"
                  >
                    <div class="status-box mr-3" :class="statusBoxColor(item)">
                      <div :class="statusIcon(item)"></div>
                    </div>
                    <span>{{ StatusName(item) }}</span>
                  </div>
                </div>
                <CElementCover
                  :opacity="1"
                  v-show="Loading"
                >
                  <CSpinner size="sm" />
                </CElementCover>
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow v-if="this.currentStep === 2">
          <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
            <CButton
              square
              size="sm"
              color="excel"
              class="d-flex align-items-center"
              @click="printPdf()"
              >
                <CIcon name="cil-cloud-download"/><span class="ml-1">{{$t('label.download')}}</span>
              </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12" lg="5" style="text-align: right;">
            <h6 class="mt-2 ml-3"> <b>{{ `${$t('label.block')} ${this.$store.state.yard.YardBlock}` }}</b></h6>
          </CCol>
          <CCol sm="12" lg="5" style="text-align: center;">
            <h6 class="mt-2"> <b>{{ `${$t('label.height_')} ${this.$store.state.yard.YardHeight}` }}</b></h6>
          </CCol>
        </CRow>
        <CRow align-horizontal="center">
          <CCol sm="8" >
            <bay-grid
              :col="colNum(false)"
              :row="rowNum(false)"
              :items="positions"
              :maxCol="responsiveCol()"
              showStatus
              :currentStatus="yardSlotStatus"
              :statusCondition = 'statusConditionSelected === "0" ? false : true'
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow> 
    <LeyendaModal
      :modal.sync="leyendModal"
      @update="leyendModal = false"
    />
  </div> 
</template>
  
  <script>
  import { mapState } from 'vuex';
  import BayGrid from "./bay-grid";
  import BuqueBayFormValidations from '@/_validations/buque/BuqueBayFormValidations';
  import { BayMixin } from '@/_mixins/buque';
  import LeyendaModal from "./legend-modal";
  
  //Data
  function data() {
    return {
      Loading: false,
      leyendModal: false,
      yardSlotStatus: {
        YardSlotStatusId: '',
        YardSlotStatusName:'',
        YardSlotStatusOriginId: '',
        Class: '',
        YardSlotStatusNameEs: '',
        YardSlotStatusNameEn: '',
        FgActValidSlot: false
      },
      status: [],
      statusConditionSelected: '',
      statusConditionOptions:[
        {
          value:0,
          conditionStatusEn:'DISABLED',
          conditionStatusEs:'DESHABILITADO'
        },
        {
          value:1,
          conditionStatusEn:'HABILITED',
          conditionStatusEs:'HABILITADO'
        }
      ]
    }
  }
  
  
  //Mounted: 
  function mounted() {
    this.getStatus();
    this.statusConditionSelected =  !this.fgOperative ? '0' : '1';
  }

  //Metodos: 
  function getStatus() {
    this.Loading = true;
    this.$http.ejecutar('GET', 'YardSlotConfigStatus-list')
    .then(response => {
      this.status = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.Loading = false;
    });
  }

  function filterCondition(event) {
    this.statusConditionSelected = event;

    if(event === '0'){
      this.yardSlotStatus= {
        YardSlotStatusId: '',
        YardSlotStatusName:'',
        YardSlotStatusOriginId: '',
        Class: '',
        YardSlotStatusNameEs: '',
        YardSlotStatusNameEn: '',
        FgActValidSlot: false
      }
     
      //this.$store.commit("yard/deleteStatus");
    }
  }

  function Update() {
    this.leyendModal = false;
  }
  
  /****** Bays ******/
  function colNum(){
    let count = 0;

    this.positions?.map(item =>{
      if(count < item?.SlotJson?.length)
        count = item?.SlotJson?.length;
    });
  
    return count;
  }
  function rowNum(){
    return this.positions?.length > 0 ? this.positions?.length :  0;
  }
  function responsiveCol() {
    let colNum = this.colNum(false);
    return colNum;
  }

  function selecStatus(item) {
    if(this.statusConditionSelected != 0){
      let _lang = this.$i18n.locale;
      let conditionName = _lang=='en' ? this.yardSlotStatus?.YardSlotStatusNameEn : this.yardSlotStatus?.YardSlotStatusNameEs;
      let ItemName = _lang=='en' ? item.YardSlotStatusNameEn : item.YardSlotStatusNameEs;
      if(ItemName === conditionName){
        this.yardSlotStatus.YardSlotStatusId = '';
        this.yardSlotStatus.YardSlotStatusNameEs = '';
        this.yardSlotStatus.YardSlotStatusNameEn = '';
      } else {
        this.yardSlotStatus = {...item};
        this.yardSlotStatus.YardSlotStatusName = item.YardSlotStatusNameEn;
      }
    }
  }

  function statusBoxColor(item) {
    if(this.statusConditionSelected !=0 ){

      let _lang = this.$i18n.locale;
      let Name = _lang=='en' ? item.YardSlotStatusNameEn : item.YardSlotStatusNameEs;
      switch (Name) {
        case this.$t('label.Disabled'):
          return 'status-box-disabled';
        default:
          return 'status-box-color';
      }
    }
    else{
      return 'status-box-disabled'
    }
  }

  function statusIcon(item) {
      let _lang = this.$i18n.locale;
      let Name = _lang=='en' ? item.YardSlotStatusNameEn : item.YardSlotStatusNameEs;
      switch (Name) {
        case  this.$t('label.ACTIVO'):
          return 'active-position';
        case this.$t('label.Disabled'):
          return 'disabled-position';
        case this.$t('label.Not_Exist'):
          return 'not-exist';
        case this.$t('label.Reefer_Plug'):
          return 'reefer-plug';
        case this.$t('label.Hazard'):
          return 'hazard-available';
        case this.$t('label.Hazard_Restriction'):
        return 'hazard-restriction';
        case this.$t('label.specialContainer'):
          return 'special-container';
        default:
          return 'default-position';
      }
  }

  function StatusName(item) {
    let _lang = this.$i18n.locale;
    let Name = _lang=='en' ? item.YardSlotStatusNameEn : item.YardSlotStatusNameEs;
    return Name
  }

  function printPdf() {

    let pageInfo = {
      currentBay: this.currentBay,
      details: {...this.yardSelected, yardBlock: this.$store.state.yard.YardBlock, yardHeight: this.$store.state.yard.YardHeight},
      bayCode: this.currentBay,
      positions: this.positions,
      type: 'yard-blocks',
    };

    localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

    let routeData = this.$router.resolve({name: 'pdfYardBlocks'});
    window.open(routeData.href, '_blank');
  }

  //Computeds:
  function isStatusSelected(item) {
    if(this.statusConditionSelected !=0 ){
      let _lang = this.$i18n.locale;
      let conditionName = _lang=='en' ? this.yardSlotStatus?.YardSlotStatusNameEn : this.yardSlotStatus?.YardSlotStatusNameEs;
      let ItemName = _lang=='en' ? item.YardSlotStatusNameEn : item.YardSlotStatusNameEs;
      return {
        'selected': conditionName == ItemName ? true : null
      }
    }
  }

  function conditionStatusFormatted(){
    let _lang = this.$i18n.locale;

    if(this.statusConditionOptions.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.statusConditionOptions.map(function(e){
          chart.push({
            value: e.value, 
            label: _lang=='en' ?  e.conditionStatusEn : e.conditionStatusEs, 
          })     
      })
      return chart;
    }
  }
  
  export default {
    name: 'tier-rows',
    mixins: [BayMixin],
    props: {
      
    },
    components: {
      BayGrid,
      LeyendaModal
    },
    data,
    validations: BuqueBayFormValidations,
    mounted,
    methods: {
      colNum,
      rowNum,
      responsiveCol,
      Update,
      statusBoxColor,
      selecStatus,
      isStatusSelected,
      statusIcon,
      getStatus,
      StatusName,
      filterCondition,
      printPdf
    },
    computed: {
      conditionStatusFormatted,
      ...mapState({
        positions: state => state.yard.positions,
        currentStep: state => state.yard.current,
        collapseHeightPlan: state => state.yard.collapseHeightPlan, 
        yardSelected: state => state.yard.yardSelected,
        fgOperative: state => state.yard.fgOperative,
      })
    },
    watch:{
      collapseHeightPlan: function (val) {
        this.isStatusSelected(this.status[0]);
        this.selecStatus(this.status[0]); 
     }
  }
  }
  </script>
  
  <style scoped>
    .status-wrapper{
      width: 100%;
      padding: 10px;
      border:2px solid #000;
      margin-top: 10px;
    }
  .status-wrapper>label{
    font-size: 16px;
    font-weight: bolder;
    color: #000;
    position:absolute;
    top:-4px;
    left:30px;
    background-color:white;
  }

  .status-wrapper .status-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .status-wrapper .status-container .status-item {
    cursor: pointer;
    width: calc(25% - 10px);
    margin: 5px 5px 0;
    height: 50px;
    border: 1px solid #000;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .status-wrapper .status-container .status-item:hover {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
  }
  .status-wrapper .status-container .status-item.selected {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
    background: #2eb85c;
    color: #fff;
  }

  .status-box {
    position: relative;
    width: 40px;
    height: 40px;

    border-radius: 2px;
    color: #fff !important;
  }
  .status-box-activo {
    background: transparent;
    color: #000;
  }
  .status-box-color {
    background:#eeeded;
  }
  .status-box-inactive {
    background: #eeeded;
  }
  .status-box-disabled {
    background: #9e9e9e;
  }
  .dash{
    border: 0 none;
    border-top: 1px dashed #322f32;
    background: none;
    height:0;
  }

  .hazard-available {
    background-image: url('/img/iconos/AvailableHazard.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .hazard-restriction {
    background-image: url('/img/iconos/HazardRestriction.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .reefer-plug {
    background-image: url('/img/iconos/ReeferPlug.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .not-exist {
    background-image: url('/img/iconos/NotExist.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .disabled-position {
    background-image: url('/img/iconos/Disabled.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .special-container {
    background-image: url('/img/yards/blocks/SPECIAL-CONTAINER-NEGRO.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .default-position {
    background-image: url('/img/iconos/Default.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .active-position {
    background-image: url('/img/iconos/Activo.svg');
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  </style>